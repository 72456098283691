
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import store from "@/store";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import PaginationUtill from "../util/PaginationUtill";

export default defineComponent({
  name: "CZPositionDataTable",
  components: { TableTab },
  setup() {
    const addition = ref(false);
    const colnames = ref();
    const PositionDataTable = ref([]);
    const loading = ref<boolean>(false);
    interface Tableheader {
      name: string;
      key: string;
      sortable: boolean;
    }
    const pageheader = "Position Data table";
    const columnHeaders = ref<Array<Tableheader>>();

    //getPositionDatatable();
    const MainValues = ref({
      savings_per: "",
      locked_savings: "",
      customer_Name: "",
      supplier_Name: "",
      commodity: "",
      startDate: "",
      duration: "",
      powerReport: "",
      manager: "",
      startYear: "",
    });
    loading.value = true;
    const OriginalRow1 = ref();
    onMounted(() => {
      setCurrentPageBreadcrumbs("Position Data table", [
        "Flexible Procurement",
      ]);
      addition.value = false;
      // getPositionDatatable();
    });

    //const selectedType = ref([]);

    /**
   
   
     *@description For auto refreshing the page on selection change
     */
    // watch(
    //   () => [
    //     store.getters.layoutConfig("flexibleProcurement.scenarioName"),
    //     store.getters.layoutConfig("flexibleProcurement.reportName"),
    //     store.getters.layoutConfig("flexibleProcurement.current"),
    //   ],
    //   // function () {
    //   //   getPositionDatatable();
    //   // }
    // );
    let AccountId = store.getters.AccountId;
    let current = store.getters.layoutConfig("flexibleProcurement.current");
    let FPreportName = store.getters.layoutConfig(
      "flexibleProcurement.reportName"
    );
    let FPscenarioName = store.getters.layoutConfig(
      "flexibleProcurement.scenarioName"
    );
    const accountInfo: any = {
      clientId: AccountId,
      productId: 4,
      reportId: FPreportName,
      scenarioId: FPscenarioName,
      iscurrent: current,
    };
    ApiService.post("api/FlexProcurement/getFlexProcrement", accountInfo)
      .then(async ({ data }) => {
        await data;
        colnames.value = PaginationUtill.PositiondataTableColNames.map(
          (a) => a.key
        );
        columnHeaders.value = PaginationUtill.PositiondataTableColNames.map(
          (a) => ({
            name: a.name,
            key: a.key,
            sortable: true,
          })
        );
        PositionDataTable.value = data.dayCostList.map((a) => a);
        OriginalRow1.value = data.cumulative_values;
        MainValues.value = {
          savings_per: data.savings_per,
          locked_savings: data.locked_savings,
          customer_Name: data.customer_Name,
          supplier_Name: data.supplier_Name,
          commodity: data.commodity,
          startDate: data.startDate,
          duration: data.duration,
          powerReport: data.powerReport,
          manager: data.manager,
          startYear: data.startYear,
        };
        addition.value = true;
        loading.value = false;
      })
      .catch((a) => {
        loading.value = false;
        console.log("Api Log Error", a);
      });

    return {
      columnHeaders,
      PositionDataTable,
      //fieldsList,
      //selectedType,
      //  defaultHeders,
      OriginalRow1,
      MainValues,
      loading,
      colnames,
      pageheader,
      addition,
    };
  },
});
